// extracted by mini-css-extract-plugin
export var article = "style-module--article--f0325";
export var backButton = "style-module--backButton--27823";
export var blankPage = "style-module--blankPage--ec4b2";
export var blue = "style-module--blue--abdc0";
export var closeButton = "style-module--closeButton--9896d";
export var header = "style-module--header--70f66";
export var heading = "style-module--heading--f9c87";
export var headingInner = "style-module--headingInner--78da8";
export var large = "style-module--large--5ce5e";
export var main = "style-module--main--ec4de";
export var pink = "style-module--pink--3f390";
export var subTitle = "style-module--subTitle--347f7";
export var superTitleInner = "style-module--superTitleInner--110ba";
export var white = "style-module--white--88a81";